function toggleMenu(event) {
    let target = event.currentTarget;
    let overlay = document.getElementById('menu-overlay');
    target.classList.toggle('active');
    overlay.classList.toggle('open');
    event.stopPropagation();

    if (target.id === 'menu-toggle-desktop') {
        document.body.addEventListener('click', (e) => {
            if (target.classList.contains('active')) {
                target.classList.remove('active');
            }
            if (overlay.classList.contains('open')) {
                overlay.classList.remove('open');
            }
        });
    }
}

function getElements() {
    return document.querySelectorAll('.menu-toggle');
}

function bindEvents() {
    [].map.call(getElements(), (element) => {
        element.addEventListener('click', toggleMenu);
    });
}

document.addEventListener('turbolinks:load', () => {
    bindEvents();
})
