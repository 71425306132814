// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

require("@rails/ujs").start()
require("turbolinks").start()
require("@rails/activestorage").start()
require('jquery')
require('inputmask')

import smoothscroll from 'smoothscroll-polyfill';
// kick off the polyfill!
smoothscroll.polyfill();

//Application SCSS.
import "stylesheets/application"

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//VueJS imports
// import Vue from 'vue/dist/vue.esm'
// import inputPassword from '../components/InputPassword.vue';

// document.addEventListener('DOMContentLoaded', () => {
//   const app = new Vue({
//     el: '#app',
//     components: { inputPassword }
//   })
// })

// import * as SampleModule from '../modules/SampleModule';
// window.SampleModule = SampleModule;

import * as Breadcrumb from "../modules/Breadcrumb";
import * as DocumentsTabs from "../modules/DocumentsTabs";
import * as HideOnClickOutside from "../modules/HideOnClickOutside";
import * as OfferingMenu from "../modules/OfferingMenu";
import * as Popups from "../modules/Popups";
import * as SnackBar from "../modules/SnackBar";
import * as TimedDocs from "../modules/TimedDocs";
import * as ToggleMenu from "../modules/ToggleMenu";
import * as TogglePassword from "../modules/TogglePassword";
import * as ValidateForms from "../modules/ValidateForms";
import * as ValidatePassword from "../modules/ValidatePassword";
import * as d3 from 'd3';
import c3 from 'c3';

window.HideOnClickOutside = HideOnClickOutside;
window.TogglePassword = TogglePassword;
window.ValidatePassword = ValidatePassword;
window.ToggleMenu = ToggleMenu;
window.DocumentsTabs = DocumentsTabs;
window.OfferingMenu = OfferingMenu;
window.SnackBar = SnackBar;
window.TimedDocs = TimedDocs;
window.ValidateForms = ValidateForms;
window.Breadcrumb = Breadcrumb;
window.Popups = Popups;
window.d3 = d3;
window.c3 = c3;