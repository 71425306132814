function onKeyUp(event) {
    const element = event.currentTarget;
    validate(element);
}

function validateMinimumLenght(string) {
    let pattern = new RegExp("^.{8,}$");
    return pattern.test(string);
}

function validateOneNumber(string) {
    let pattern = new RegExp("[1-9]");
    return pattern.test(string);
}

function validateOneLowerCase(string) {
    let pattern = new RegExp("[a-z]");
    return pattern.test(string);
}

function validateOneUpperCase(string) {
    let pattern = new RegExp("[A-Z]");
    return pattern.test(string);
}

function validateOneSymbol(string) {
    let pattern = new RegExp(/[@#!$%^&*()_+\-=\[\]{};'":\\|,.<>\/?]/);
    return pattern.test(string);
}

function validate(element) {
    let validationList = element.closest('.password-validate').querySelector('.password-validate-list');
    let score = 0;

    if (validateMinimumLenght(element.value)) {
        score++;
        validationList.querySelector('.min-lenght').classList.add('text-green-100');
    } else {
        validationList.querySelector('.min-lenght').classList.remove('text-green-100');
        score--;
    }

    if (validateOneNumber(element.value)) {
        score++;
        validationList.querySelector('.one-number').classList.add('text-green-100');
    } else {
        validationList.querySelector('.one-number').classList.remove('text-green-100');
        score--;
    }

    if (validateOneLowerCase(element.value)) {
        score++;
        validationList.querySelector('.one-lowercase').classList.add('text-green-100');
    } else {
        validationList.querySelector('.one-lowercase').classList.remove('text-green-100');
        score--;
    }

    if (validateOneSymbol(element.value)) {
        score++;
        validationList.querySelector('.one-symbol').classList.add('text-green-100');
    } else {
        validationList.querySelector('.one-symbol').classList.remove('text-green-100');
        score--;
    }

    if (validateOneUpperCase(element.value)) {
        score++;
        validationList.querySelector('.one-uppercase').classList.add('text-green-100');
    } else {
        validationList.querySelector('.one-uppercase').classList.remove('text-green-100');
        score--;
    }

    if (score < 0) {
        validationList.querySelector('.score').classList.remove('text-green-100');
        validationList.querySelector('.score').classList.add('text-orange-100');
        validationList.querySelector('.score').innerHTML = 'very low';
    } else if (score === 1) {
        validationList.querySelector('.score').classList.remove('text-green-100');
        validationList.querySelector('.score').classList.add('text-orange-100');
        validationList.querySelector('.score').innerHTML = 'low';
    } else if (score === 3) {
        validationList.querySelector('.score').classList.add('text-green-100');
        validationList.querySelector('.score').innerHTML = 'high';
    } else {
        validationList.querySelector('.score').classList.add('text-green-100');
        validationList.querySelector('.score').innerHTML = 'very high';
    }
}

function getElements() {
    return document.querySelectorAll('[data-validate-password]');
}

function bindEvents() {
    [].map.call(getElements(), (element) => {
        element.onkeyup = onKeyUp;
    });
}

function validationsTempalte() {
    return `
        <div class="font-primary font-normal text-gray-300 mt-3 text-sm">
            <p>Password strenght: <span class="score text-orange-100"></span></p>
            <p class="min-lenght">/ 8 characters minimum</p>
            <p class="one-number">/ One number</p>
            <p class="one-lowercase">/ One lowercase</p>
            <p class="one-symbol">/ One symbol</p>
            <p class="one-uppercase">/ One uppercase</p>
        </div>
    `;
}

document.addEventListener('turbolinks:load', () => {
    bindEvents();
    let validationsList = document.querySelector('.password-validate-list');
    if (validationsList) {
        return validationsList.insertAdjacentHTML('beforeend', validationsTempalte());
    }
})
