function hideElementAfterShow(element) {
    setTimeout(function () {
        element.classList.remove('fadein');
        element.classList.add('fadeout');
        //Remove element after animation
        setTimeout(() => element.remove(), 500);
    }, 7000);
}

function show(message, type) {
    let template = `
        <div id="snackbar" class="fadein flex justify-center">
            <div class="${type === 'notice' ? 'bg-green-100' : 'bg-red-100'} shadow p-3 text-white lg:max-w-4xl">
                ${message}
            </div>
        </div>
    `;
    document.body.insertAdjacentHTML('afterend', template);
    let element = document.getElementById('snackbar');
    return hideElementAfterShow(element);
}


export function alert(message) {
    return show(message, 'alert');
}

export function notice(message) {
    return show(message, 'notice');
}