function hideTimedDocs(withClass) {
    [].map.call(getElements(), (element) => {
        if (element.classList.contains(withClass)) {
            element.innerHTML = '<div class="timed-out-docs"><p>This document section has expired for security reasons.</p><p>To access the documents please <a class="btn p-1" href="javascript:location.reload();">reload</a> the page.</p></div>';
        }
    });
}

function getElements() {
    return document.querySelectorAll('.timed-docs');
}

function bindEvents() {
    let timestamp = new Date().getTime();
    let withClass = 'timed-docs-' + timestamp;
    let needTimeOut = false;
    [].map.call(getElements(), (element) => {
        element.classList.add(withClass);
        needTimeOut = true;
    });
    if (needTimeOut) {
        setTimeout(hideTimedDocs, 600000, withClass);
    }
}

document.addEventListener('turbolinks:load', () => {
    bindEvents();
});
