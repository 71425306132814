let breadcrumbs;

function hideStepsOnMobile() {
  breadcrumbs.forEach((item, index) => {
    const previousElement = item.previousElementSibling;
    if (previousElement && previousElement.classList.contains("active")) return;
    item.classList.add("hidden");
  });
}

function init() {
  hideStepsOnMobile();
}

document.addEventListener("turbolinks:load", () => {
  breadcrumbs = document.querySelectorAll(".breadcrumb > div:not(.active)");

  const matchMedia = window.matchMedia("(max-width: 640px)");
  if (matchMedia.matches) {
    init();
  }

  matchMedia.addEventListener("change", (event) => {
    if (event.matches) {
      init();
    } else {
      breadcrumbs.forEach((item) => {
        item.classList.remove("hidden");
      });
    }
  });
});
