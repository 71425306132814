/*
This file is in charge of all JS logic for Popups.
It is separated in three sections:

1. Public functions (to be used from view)
2. Private functions (to be used within this scope)
3. JS triggered on document ready. 
*/

// 1. PUBLIC FUNCTIONS
export function hidePhone(number) {
    number = number.toString();
    let lastFour = number.slice(-4);
    number = number.replace('(', '').replace(')', '').replaceAll(/[0-9]/g, '*').replace(/.{4}$/, lastFour);
    return number;
}

export var sendCode = async function (phoneNumber) {
    const result = await $.ajax({
        url: '/send_2fa_sms',
        data: { phone: phoneNumber },
    });

    return result.res
}

export function toggleButtonEnabled(btn) {
    var bool = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : true;

    if (bool) {
        btn.classList.remove('opacity-50', 'cursor-not-allowed', 'disabled');
    } else {
        btn.classList.add('opacity-50', 'cursor-not-allowed', 'disabled');
    }
}

export var verifyCode = async function (phone, code, fromProfile) {
    const result = await $.ajax({
        url: '/verify_2fa_code',
        type: 'POST',
        data: { phone: phone, code: code, from_profile: fromProfile }
    });

    return result;
}

export function send_code_to_user_phone(phoneNumber) {
    sendCode(phoneNumber).then(res => { res === 200 ? "" : alert(res) });
    updateDOM(phoneNumber);
}

// 2. PRIVATE FUNCTIONS
function getCookie(name) { // Get country code cookie
    var dc = document.cookie;
    var prefix = name + "=";
    var begin = dc.indexOf("; " + prefix);
    if (begin == -1) {
        begin = dc.indexOf(prefix);
        if (begin != 0) return null;
    }
    else {
        begin += 2;
        var end = document.cookie.indexOf(";", begin);
        if (end == -1) {
            end = dc.length;
        }
    }
    return decodeURI(dc.substring(begin + prefix.length, end));
}

var lostAuth = async function () { // User lost authenticator method.
    const result = await $.ajax({
        url: '/lost_authenticator',
        type: 'GET'
    });

    return result.res;
}

function updatePopupToSetupComplete() {
    document.getElementById('try-again-div') ? document.getElementById('try-again-div').remove() : console.log("No try-again-div found.");
    document.getElementById('input-code') ? document.getElementById('input-code').remove() : console.log("No input-code found.");
    document.getElementById('popup-title').innerText = "Authentication setup complete";
    document.getElementById('popup-text').innerText = "Next time you log in to Tunnel, we'll send you a six digit code confirmation code via SMS that you'll use to verify your account.";
    document.getElementById('confirm-verification') ? document.getElementById('confirm-verification').remove() : console.log("No confirm-verification found.");
    document.getElementById('error-code') ? document.getElementById('error-code').remove() : console.log("No error-code found.");
    document.getElementById('lost-authenticator') ? document.getElementById('lost-authenticator').remove() : console.log("No error-code found.");


    let popupButton = document.createElement("button");
    popupButton.className = "h-10 px-6 my-2 text-sm font-medium bg-orange-100 text-white transition-colors duration-150 rounded-md focus:shadow-outline";
    popupButton.textContent = "Continue";
    popupButton.onclick = function () {
        if (document.getElementById('from_user_profile')) {
            document.location.href = '/two_factor_login?from=profile'
        } else {
            document.location.href = '/two_factor_login'
        }
    };

    document.getElementById('popup-footer-div').appendChild(popupButton);
}

function updateDOM(phoneNumber, authLost = true) {
    // Remove Phone Input.
    document.getElementById('phone').parentNode.remove();
    document.getElementById('skip-2fa').remove();
    document.getElementById('send-verification').remove();

    // Create Elements to append.
    let input = document.createElement("input"); // 6 Digit Code input.
    input.type = "text";
    input.maxLength = 6;
    input.pattern = /\d*/;
    input.placeholder = "Enter 6-digit code";
    input.className = "mt-2 py-3 border rounded border-gray-200 border-opacity-25 text-center text-md md:text-lg font-semibold";
    input.id = "input-code"

    let confirmVerificationButton = document.createElement("button"); // Confirm Code button.
    confirmVerificationButton.className = "py-3 px-4 my-2 text-sm font-medium bg-orange-100 text-white rounded-sm font-semibold md:text-base uppercase transition-colors duration-150 focus:shadow-outline opacity-50 cursor-not-allowed disabled";
    confirmVerificationButton.id = "confirm-verification"
    confirmVerificationButton.textContent = "Confirm verification"

    let lostAuthenticator = document.createElement("a");
    lostAuthenticator.className = "text-sm"
    lostAuthenticator.style.cursor = "pointer";
    lostAuthenticator.innerText = "Have you lost access to your two-factor authentication phone? If so, click here and a Tunnel representative will get in contact with you.";
    lostAuthenticator.id = "lost-authenticator";

    let lineBreak = document.createElement("br");

    // Append created elements.
    document.getElementById('popup-body').appendChild(input);
    document.getElementById('popup-footer-div').appendChild(confirmVerificationButton);
    document.getElementById('popup-footer-div').appendChild(lineBreak);
    if (authLost) { document.getElementById('popup-footer-div').appendChild(lostAuthenticator); }
    document.getElementById('try-again-div').hidden = false;
    document.querySelector('#popup-body p').innerHTML = "Please enter the verification code we sent to " + hidePhone(phoneNumber) + ". It may take a few minutes to receive the code.";

    // Set input code restrictions (max 6, only numbers)
    const inputCode = document.getElementById('input-code');
    inputCode.addEventListener("keypress", function () {
        if (this.value.length == 6) { return false }
        this.value = this.value.replace(/[^0-9]/g, '');
    });

    // Listener functions for generated elements.
    confirmVerificationButton.addEventListener('click', function () {
        if (this.classList.contains('disabled')) { return } // Do nothing if disabled.

        // Hide errors
        if (document.getElementById('error-code')) {
            document.getElementById('error-code').style.visibility = "hidden";
        }

        // Get input values for code and phone.
        let code = inputCode.value;
        let phone = phoneNumber;

        toggleButtonEnabled(confirmVerificationButton, false);

        // Append Error Code element (if it doesn't already exist).
        if (document.getElementById('error-code') === null) {
            let codeError = document.createElement("span");
            codeError.id = "error-code"
            codeError.className = "mx-2";
            codeError.style.color = "rgb(227, 66, 52)";
            codeError.style.display = "inline-block";
            codeError.style.maxWidth = "50%";
            codeError.style.textAlign = "center";
            codeError.style.verticalAlign = "middle";
            codeError.hidden = true;

            document.getElementById('popup-body').append(codeError);
        }

        let fromProfile = document.getElementById('from_user_profile') ? true : false;
        // Logic to determine if input code is correct or not.
        verifyCode(phone, code, fromProfile).then(res => {
            // Show error message.
            if (res.success) {
                if (document.getElementById('two_factor_enabled') != null) { // If 2FA set to true:
                    window.location.href = '/auth';
                } else { // Load last step of popup
                    updatePopupToSetupComplete();
                }
            } else {
                document.getElementById('error-code').innerText = res.error;
                document.getElementById('error-code').style.visibility = "visible";
            }
        });
    });

    lostAuthenticator.addEventListener('click', function () {
        lostAuth().then(res => {
            if (res) { // Success. Update DOM
                const popupFooter = document.getElementById('popup-footer-div');
                document.getElementById('input-code').remove();
                while (popupFooter.firstChild) { popupFooter.removeChild(popupFooter.lastChild); }

                document.getElementById('popup-text').innerText = "Thanks for reaching out. You can expect a member of our team to contact you soon.";
                document.getElementById('try-again-div').remove();

                let noticeText = document.createElement("p");
                noticeText.innerText = "You will be redirected soon...";
                popupFooter.appendChild(noticeText);
                setTimeout(function () { window.location.href = '/'; }, 5000); // Redirect to root
            } else {
                alert("Could not reset authenticator. Please try again later or contact us.")
            }
        })
    });

    // If input code is 6 digits, enable Confirm button.
    inputCode.addEventListener('input', function () {
        this.value.length === 6 ? toggleButtonEnabled(confirmVerificationButton) : toggleButtonEnabled(confirmVerificationButton, false)
    })

    // Time left until user can resend code.
    var timeLeft = 60;
    const timeLeftEl = document.getElementById('time-left')
    let intervalID = setInterval(function () {
        if (timeLeft <= 0) {

            // Append 'resend code' button
            clearInterval(intervalID); // Stop this interval execution.
            let resendCodeButton = document.createElement("button");
            resendCodeButton.href = "#";
            resendCodeButton.innerText = "Resend code";
            resendCodeButton.className = "text-sm md:text-base text-primary-600 underline mt-2 try-again-button";
            timeLeftEl.parentElement.remove();

            // Some times this interval is over after the user has already left the page.
            // Resulting in an error to find the element after 30 seconds have passed.
            // To prevent that, we clear the interval if we can't find the element.
            document.getElementById('try-again-div') ? document.getElementById('try-again-div').appendChild(resendCodeButton) : clearInterval(intervalID)

            resendCodeButton.addEventListener('click', function () {
                if (this.classList.contains('re-sent')) { return }
                this.classList.remove('text-primary-600', 'underline');
                this.style.color = "rgb(203 213 225)";
                this.textContent = "Verification code sent.";
                this.classList.add('re-sent')
                sendCode(phoneNumber).then(res => { res === 200 ? "" : alert(res) });
            })
        } else {
            timeLeft -= 1;
            timeLeftEl.innerText = timeLeft;
        }
    }, 1000);
}

// 3. DOCUMENT READY TRIGGERS
$(document).ready(function () {
    // Prevent unnecessary memory usage by returning if we're not using popups in the page.
    if (!window.intlTelInput) { return }
    // Elements to target in DOM.
    const sendVerificationButton = document.getElementById('send-verification');
    const phoneInputField = document.getElementById('phone')
    const modalEl = document.getElementById('info-popup');

    // International Telephone Input: telephone input field with country code and flag.
    const phoneInput = window.intlTelInput(phoneInputField, {
        initialCountry: "auto",
        geoIpLookup: function (success, failure) {
            // If country code is already saved as a cookie, use that.
            if (getCookie('countryCode')) {
                console.log("Country code:" + getCookie('countryCode'));
                success(getCookie('countryCode'));
                return
            }

            // Get country code and save it as a cookie for 1 hour.
            let hours = 1;
            let oneHourFromNow = new Date(new Date().getTime() + hours * 60 * 60 * 1000).toDateString();

            // Renew token: https://ipinfo.io (needs account) 
            $.get("https://ipinfo.io?token=876c899deb4de2", function () { }, "jsonp").always(function (resp) {
                var countryCode = (resp && resp.country) ? resp.country : "us";
                document.cookie = `countryCode=${countryCode}; expires=${oneHourFromNow}`;
                success(countryCode);
            });
        },
        utilsScript:
            "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/17.0.14/js/utils.js",
    });



    // Declare and initialize pop-ups.
    const twoFactorModal = new Modal(modalEl, {
        placement: 'center',
        backdrop: 'static',
        onHide: () => {
            twoFactorModal.show() // Modal cannot be closed.
        },
    });


    /* Listeners for elements */

    // Check if phone is valid and enable Send Verification button if so.
    phoneInputField.addEventListener('input', function () {
        if (this.value.trim()) {
            let incorrectCodeDOM = document.getElementById('incorrect-phone');
            incorrectCodeDOM ? incorrectCodeDOM.hidden = true : false; // Hide element if present.

            if (phoneInput.isValidNumber()) {
                toggleButtonEnabled(sendVerificationButton, true);
            } else {
                if (!incorrectCodeDOM) { // Create element if it doesn't exist.
                    let incorrectPhoneSpan = document.createElement("span");
                    incorrectPhoneSpan.className = "incorrect-phone";
                    incorrectPhoneSpan.innerText = "Incorrect phone number.";
                    incorrectPhoneSpan.id = "incorrect-phone";
                    document.getElementsByClassName('iti--allow-dropdown')[0].appendChild(incorrectPhoneSpan);
                } else { incorrectCodeDOM.hidden = false; } // Hide it if exists.
                toggleButtonEnabled(sendVerificationButton, false);
            }
        }
    })

    // Send Verification button actions.
    sendVerificationButton.addEventListener('click', function () {
        // Do nothing if button is disabled.
        if (this.classList.contains('disabled')) { return }

        // Get input number in international format (E.164 standard)
        let phoneNumber = phoneInput.getNumber()
        sendCode(phoneNumber).then(res => { res === 200 ? "" : alert(res) });
        updateDOM(phoneNumber, false);
    });

    twoFactorModal.show();

    // Send 2FA Code automatically if user has 2FA enabled.
    var twoFactorEnabled = document.getElementById('two_factor_enabled');
    if (twoFactorEnabled != null) { send_code_to_user_phone(twoFactorEnabled.value) }

});