export function element(element) {
    const outsideClickListener = event => {
        if (!element.contains(event.target) && (element.parentNode !== event.target.parentNode) && isVisible(element)) {
            element.classList.add('hidden');
            removeClickListener();
        }
    }
    const removeClickListener = () => {
        document.removeEventListener('click', outsideClickListener)
    }
    document.addEventListener('click', outsideClickListener)
}

const isVisible = elem => !!elem && !!(elem.offsetWidth || elem.offsetHeight || elem.getClientRects().length)