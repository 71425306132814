let offeringMenu;
let offeringSectionsContainer;
let offeringMenuSection;
let offeringMenuSectionsList;

function bindObserverEnvent() {
    let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            if (entry.isIntersecting) {
                offeringMenu.classList.remove('fixed');
                offeringMenu.classList.add('absolute');
                return true;
            }
            offeringMenu.classList.remove('absolute');
            offeringMenu.classList.add('fixed');
            return false;
        });
    });
    observer.observe(document.querySelector('footer'));


    const options = {
        rootMargin: "-45% 0px -45% 0px"
    };
    let sectionsObserver = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
            let element = offeringMenuSectionsList.querySelector(`a[href='#${entry.target.id}']`);
            if (entry.isIntersecting) {
                cleanAllMenuActiveLinks();
                element.classList.add('text-secondary');
            } else {
                if (element.classList.contains('text-secondary')) {
                    let previousElement = element.previousElementSibling;
                    if (previousElement && element.getAttribute('href') !== '#documents') {
                        previousElement.classList.add('text-secondary');
                        element.classList.remove('text-secondary');
                    }
                }
            }
        });
    }, options);
    [].forEach.call(offeringSectionsContainer.children, (el) => {
        sectionsObserver.observe(el);
    });
}

function bindSectionsEvent() {
    let sectionsButton = document.getElementById('offering-menu-sections');
    if (!sectionsButton) {
        return null;
    }
    sectionsButton.onclick = toggleSectionsMenu;
}

function toggleSectionsMenu(event) {
    HideOnClickOutside.element(offeringMenuSectionsList);

    offeringMenuSection.classList.toggle("mt-2");
    offeringMenuSectionsList.classList.toggle("hidden");

    // Get the id 'invest-button' and make it 'hidden'
    let investButton = document.getElementById('invest-button');
    if (investButton) {
        investButton.classList.toggle("hidden");
    }

    // Add height: 100vh and overflow: hidden to body
    document.body.classList.toggle("overflow-hidden");
    document.body.classList.toggle("h-screen");
}

function cleanAllMenuActiveLinks() {
    [].forEach.call(offeringMenuSectionsList.children, (el) => {
        el.classList.remove('text-secondary');
    });
}

function bindOfferingMenuSectionsList() {
    let list = document.getElementById('offering-menu-sections-list');
    list.querySelectorAll('a').forEach((link) => {
        const href = link.getAttribute('href');

        if (!href.includes('#')) {
            return;
        }

        link.addEventListener('click', function (e) {
            e.preventDefault();
            list.classList.add('hidden');
            let wHeight = window.innerHeight;
            const yOffset = -(Math.round(45 / 100 * wHeight));
            console.log(yOffset);
            const element = document.querySelector(this.getAttribute('href'));
            const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;
            window.scrollTo({top: y, behavior: 'smooth'});
        });
    });
}

function bindInvestButtonClick() {
    let investButton = document.getElementById('invest-button');
    if (!investButton) {
        return null;
    }
    investButton.addEventListener('click', function (e) {
        let investOptionsModal = document.getElementById('invest-options-modal');
        investOptionsModal.classList.remove('hidden');
    });
}

function init() {
    bindObserverEnvent();
    bindSectionsEvent();
    bindOfferingMenuSectionsList();
    // bindInvestButtonClick();
}

document.addEventListener('turbolinks:load', () => {
    offeringMenu = document.getElementById('offering-menu');
    if (!offeringMenu) {
        return;
    }
    offeringSectionsContainer = document.getElementById('offering-sections-container');
    offeringMenuSection = document.getElementById('offering-menu-section');
    offeringMenuSectionsList = document.getElementById('offering-menu-sections-list');
    init();
})
