function init() {
  // Add global mask assignments using input name attribute
  Inputmask({mask: "(99999)|(99999-9999)", keepstatic: true}).mask($('input[name="postal_code"]'));
  Inputmask("(999)-999-9999").mask($('input[name="phone"]'));
  Inputmask("999999999").mask($('input[name="ein_tax_id"]'));
}

document.addEventListener('turbolinks:load', () => {
  init();
})
