function init() {
    let element = document.querySelector('.documents-tabs-contaier');
    if (!element) {
        return;
    }
    element.addEventListener('click', (event) => {
        element.classList.toggle('overflow-hidden');
        element.classList.toggle('open');
        element.classList.toggle('h-auto');
    });

    let options = element.querySelectorAll('.documents-tab');
    options.forEach((option) => {
        option.addEventListener('click', (event) => {
            if (event.currentTarget.classList.contains('active')) {
                return event.preventDefault();
            }
            options.forEach(el => el.classList.remove('active'));
            event.currentTarget.classList.add('active');
        });
    });
}

document.addEventListener('turbolinks:load', () => {
    init();
})